var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("search-list")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center pb-4"
  }, [_vm._v(_vm._s(_vm.$t("search-hibah-list")))]), _vm._m(0), _vm.hibahs == null ? _c('div', {
    staticClass: "p-5 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])]) : _vm._e(), _vm.hibahs ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "500px"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v("No. MyKad / Lama")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(_vm.hibahs, function (hibah, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(hibah.id))]), _c('td', [_vm._v(_vm._s(hibah.user ? hibah.user.name : "-"))]), _c('td', [_vm._v(" " + _vm._s(hibah.user ? hibah.user.ic_number : "-") + " ")]), hibah.hibah_type_id != 12 ? _c('td', [_c('router-link', {
      staticClass: "btn-sm btn-primary",
      attrs: {
        "tag": "button",
        "block": "",
        "to": {
          name: 'trust.part-a',
          query: {
            trust_id: hibah.id,
            pengesahan: true
          }
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("verification")))])], 1) : _vm._e(), hibah.hibah_type_id == 12 ? _c('td', [_c('router-link', {
      staticClass: "btn-sm btn-primary",
      attrs: {
        "tag": "button",
        "block": "",
        "to": {
          name: 'trust-onecent.part-d',
          query: {
            trust_id: hibah.id,
            pengesahan: true
          }
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("verification")))])], 1) : _vm._e()]);
  }), _vm.hibahs.length == 0 ? _c('tr', [_c('td', {
    staticClass: "p-5 text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])])]) : _vm._e()], 2)])]) : _vm._e(), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mt-4 pt-2"
  }, [_c('ul', {
    staticClass: "pagination justify-content-center mb-0"
  }, [_c('li', {
    staticClass: "page-item"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "javascript:void(0)",
      "aria-label": "Previous"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-arrow-left"
  }), _vm._v(" " + _vm._s(_vm.$t("prev")) + " ")])]), _vm._m(2), _vm._m(3), _vm._m(4), _c('li', {
    staticClass: "page-item"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "javascript:void(0)",
      "aria-label": "Next"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _c('i', {
    staticClass: "mdi mdi-arrow-right"
  })])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0 card border-0 sidebar sticky-bar"
  }, [_c('div', {
    staticClass: "widget"
  }, [_c('div', {
    staticClass: "widget-search mb-0",
    attrs: {
      "id": "search2"
    }
  }, [_c('form', {
    staticClass: "searchform",
    attrs: {
      "role": "search",
      "method": "get",
      "id": "searchform"
    }
  }, [_c('div', [_c('input', {
    staticClass: "border rounded",
    attrs: {
      "type": "text",
      "name": "s",
      "id": "s",
      "placeholder": "Search..."
    }
  }), _c('input', {
    attrs: {
      "type": "submit",
      "id": "searchsubmit",
      "value": "Search"
    }
  })])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-9 col-md-7"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Showing 1–15 of 47 results")])])]), _c('div', {
    staticClass: "col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    }
  }, [_c('option', [_vm._v("Show 5 lists")]), _c('option', [_vm._v("Show 10 lists")]), _c('option', [_vm._v("Show 15 lists")]), _c('option', [_vm._v("Show 20 lists")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "page-item active"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("1")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "page-item"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("2")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "page-item"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("3")])]);
}]

export { render, staticRenderFns }